<template>
  <div>
    <el-tabs v-model="currentTabName">
      <el-tab-pane
        label="相關公司列表"
        name="basic"
        v-if="isShowBasicTab">
        <RelatedCompanyListContainer
          v-loading="isPersonDataLoading"
          :companys="personData.companys"
          :queryCondition="currentQueryCondition">
        </RelatedCompanyListContainer>
      </el-tab-pane>
      <el-tab-pane
        label="風險指標"
        name="risk"
        v-if="isShowRiskTab">
        <div
          class="company-container"
          v-loading="isPersonDataLoading">
          <section
            v-if="isShowVerdicts&&personData.verdicts">
            <VerdictsTable
              :verdicts="personData.verdicts"
              :keyword="currentKeyword"
              :field="currentField">
            </VerdictsTable>
          </section>
          <section
            v-if="isShowNews"
            v-loading="!personNews">
            <NewsTable
              :news="personNews"
              :keyword="currentKeyword"
              :field="currentField">
            </NewsTable>
          </section>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import * as apis from '@/apis/index.ts'
import { mapState } from 'vuex'
import RelatedCompanyListContainer from '@/components/RelatedCompanyListContainer/RelatedCompanyListContainer.vue'
import NewsTable from '@/components/NewsTable/NewsTable.vue'
import VerdictsTable from '@/components/VerdictsTable/VerdictsTable.vue'

export default {
  props: {
    'menuIndex': {}
  },
  components: {
    RelatedCompanyListContainer,
    NewsTable,
    VerdictsTable
  },
  data () {
    return {
      currentTabName: '',
    }
  },
  computed: {
    ...mapState('multiPersonDetail', [
      'basicList',
      'getList',
      'multiPersonData',
      'multiPersonNews',
      'isPersonDataLoading',
      'isPersonNewsLoading',
      'isShowCompanys',
      'isShowVerdicts',
      'isShowNews'
    ]),
    // 目前的人物
    currentKeyword () {
      return this.menuIndex
    },
    currentField () {
      if (this.basicList.length && this.currentPersonIndex >= 0) {
        return this.basicList[this.currentPersonIndex].field
      } else {
        return ''
      }
    },
    // 目前的人物對應人物陣列索引
    currentPersonIndex () {
      if (this.multiPersonData.length && this.menuIndex) {
        const keywordArr = this.basicList.map(d => d.keyword)
        return keywordArr.indexOf(this.menuIndex)
      } else {
        return ''
      }
    },
    personData () {
      if (this.multiPersonData.length && this.currentPersonIndex >= 0) {
        return this.multiPersonData[this.currentPersonIndex]
      } else {
        return {}
      }
    },
    personNews () {
      if (this.multiPersonNews.length && this.currentPersonIndex >= 0) {
        return this.multiPersonNews[this.currentPersonIndex]
      } else {
        return null
      }
    },
    currentQueryCondition () {
      if (this.basicList.length && this.currentPersonIndex >= 0) {
        return {
          basic: this.basicList[this.currentPersonIndex]
        }
      } else {
        return {}
      }      
    },
    isShowBasicTab () {
      if (this.isShowCompanys) {
        return true
      } else {
        return false
      }
    },
    isShowRiskTab () {
      if (this.isShowVerdicts || this.isShowNews || this.isShowDisputes) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
  },
  created () {
    // 預設active tab
    if (this.isShowBasicTab) {
      this.currentTabName = 'basic'
    } else if (this.isShowRiskTab) {
      this.currentTabName = 'risk'
    }
  },
}
</script>
